.work-skill {
  position: relative;
  z-index: 1;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  &:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
    background: -moz-linear-gradient(left, rgba(122, 102, 203, 1) 0%, rgba(72, 189, 197, 0.74) 100%);
    background: -webkit-linear-gradient(left, rgba(122, 102, 203, 1) 0%, rgba(72, 189, 197, 0.74) 100%);
    background: linear-gradient(to right, rgba(122, 102, 203, 0.8) 0%, rgba(72, 189, 197, 0.74) 100%);
  }
  .pie-value {
    display: block;
    position: absolute;
    font-size: 24px;
    height: 40px;
    top: 50%;
    margin-top: -26px;
    margin-left: -25px;
    left: 50%;
    color: #ffffff;
    font-weight: 600;
    line-height: 40px;
  }
  .knob {
    position: relative;
    text-align: center;
    width: 270px;
  }
}

.work-wkill .knob {
  position: relative;
}

.work-skill {
  .knob:before {
    position: absolute;
    top: 9px;
    left: 59px;
    right: 59px;
    bottom: 18px;
    content: '';
    border: 24px solid #ffffff;
    border-radius: 50%;
  }
  .skills {
    padding: 105px 0px;
    h6 {
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
      text-transform: capitalize;
    }
  }
}