.sponsors-logos {
  padding: 52px 0px;
  background: #f8f8fa;
  .owl-theme .owl-controls {
    display: none;
  }
  img {
    padding: 15px 50px;
    width: 100%;
  }
}