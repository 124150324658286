@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Source+Sans+Pro:400,600,700');

body {
  line-height: 1.4;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
}
p {
  font-family: $primary-font;
  color: #848484;
  font-size:16px;
}
h1,h2,h3,h4,h5,h6 {
	font-family: $secondary-font;
	font-weight: 600;
}